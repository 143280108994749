export default {
  methods: {
    onClickSearchProjectCode(type, index) {
      this.isPopProjectCodeSearch = true;
      this.projectCodeTarget.type = type;
      this.projectCodeTarget.index = index;
      let target = {};
      if (this.projectCodeTarget.type == "info") {
        target = this.model.purchasePriceList[this.projectCodeTarget.index];
      } else {
        target = this.model.purchaseGoodsList[this.projectCodeTarget.index];
      }
      this.selectedProject.projectCode = target.projectCode;
      this.selectedProject.projectName = target.projectName;
    },
    onClickSearchGoodsName(index) {
      this.isPopGoodsNameSearch = true;
      this.goodsNameTargetIndex = index;
      const target = this.model.purchaseGoodsList[this.goodsNameTargetIndex];
      this.selectedGoodsName.categoryID = target.categoryID;
      this.selectedGoodsName.categoryName = target.categoryName;
    },
    // 품명 조회 팝업 닫기
    onClickClosePopGoodsNameSearch() {
      this.isPopGoodsNameSearch = false;
      this.goodsNameTargetIndex = null;
      this.selectedGoodsName = {};
    },
    // 품명 조회 팝업 선택완료
    onClickCompletePopGoodsNameSearch(selectedGoodsName) {
      this.isPopGoodsNameSearch = false;
      const target = this.model.purchaseGoodsList[this.goodsNameTargetIndex];
      target.cmdbAssetInfo = selectedGoodsName;
      target.categoryID = selectedGoodsName.categoryID;
      target.categoryName = selectedGoodsName.categoryName;
      target.assetInfoNum = selectedGoodsName.assetInfoNum;
      target.accountCode = selectedGoodsName.accountCode;
      target.accountName = selectedGoodsName.accountName;
      target.madeIn = selectedGoodsName.partnerName;
      target.unitPrice = selectedGoodsName.price;
      target.warranty = selectedGoodsName.warranty;
      target.assetStatus = selectedGoodsName.assetStatus;
    },
    // 프로젝트 코드 팝업 닫기
    onClickClosePopProjectCodeSearch() {
      this.isPopProjectCodeSearch = false;
      this.projectCodeTarget.type = "";
      this.projectCodeTarget.index = null;
      this.selectedProject = {};
    },
    // 프로젝트코드 팝업 선택완료
    onClickCompletePopProjectCodeSearch(selectedProject) {
      this.isPopProjectCodeSearch = false;
      let target = {};
      let targetPrice = {};
      if (this.projectCodeTarget.type == "info") {
        target = this.model.purchasePriceList[this.projectCodeTarget.index];
      } else {
        target = this.model.purchaseGoodsList[this.projectCodeTarget.index];
        targetPrice = this.model.purchasePriceList[this.projectCodeTarget.index];
        targetPrice.projectCode = selectedProject.projectCode;
        targetPrice.projectName = selectedProject.projectName;
      }
      target.projectCode = selectedProject.projectCode;
      target.projectName = selectedProject.projectName;
    },
  },
};
