<!-- 사용안함 -->
<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">기안작성</h2>
    </div>
    <!-- 이용안내 -->
    <DraftGuide :type="1" />
    <!-- 결재선 지정 -->
    <DraftApprLine
      :init-data="draftApprLineinitData"
      :draftDeptApprLineAlertText="draftDeptApprLineAlertText"
      @updateSelectedDraftDept="onUpdateSelectedDraftDept"
      @updateDraftDeptApprLine="onUpdateDraftDeptApprLine"
      @updateProcessingApprovalLine="onUpdateProcessingApprovalLine"
      @updateReferenceApprovalLine="onUpdateReferenceApprovalLine"
      @selectedApprovalLineAccount="onSelectedApprovalLineAccount"
    />
    <!-- PdfViewer -->
    <PdfViewer :PDFDataObj="dataList" />
    <!-- 기본정보 -->
    <DraftInfo :model.sync="model" />

    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />

    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="cancelDraft">
          취소
        </button>
      </div>
      <div class="pos_right">
        <button
          type="button"
          class="btn_large btn_fourthly"
          @click.prevent="save(docStatus.TEMPORARY)"
        >
          임시 저장
        </button>
        <button
          type="button"
          class="btn_large btn_primary"
          @click.prevent="save(docStatus.PROCESSING)"
        >
          상신
        </button>
      </div>
    </div>
    <PopGoodsNameSearch
      v-if="isPopGoodsNameSearch"
      :selectedGoodsName="selectedGoodsName"
      @onClickClose="onClickClosePopGoodsNameSearch"
      @onClickComplete="onClickCompletePopGoodsNameSearch"
    />
    <PopProjectCodeSearch
      v-if="isPopProjectCodeSearch"
      :selectedProject="selectedProject"
      @onClickClose="onClickClosePopProjectCodeSearch"
      @onClickComplete="onClickCompletePopProjectCodeSearch"
    />
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapState } from "vuex";
import LocalStorageManager from "@/LocalStorageManager";

import DraftWriteModel from "./DraftWriteModel";
import DraftGuide from "@/_approval/components/shared/write/DraftGuide";
import DraftApprLine from "@/_approval/components/shared/write/DraftApprLine";
import DraftInfo from "@/_approval/components/payment/write/DraftInfo";

import DraftFile from "@/_approval/components/purchaseDraft/write/DraftFile";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";

import { docStatus, aprvlLineType, assetType } from "@/_approval/const/const";

import ApprovalLineModel from "@/_approval/pages/purchaseDraft/write/ApprovalLineModel";
import commDocs from "@/_approval/mixins/common/commDocs";

import commWriteDoc from "@/_approval/mixins/common/commWriteDoc";

import FileView from "@/components/common/file/FileView";

import pdf from "vue-pdf";

import PdfViewer from "@/components/shared/PdfViewer";

export default {
  name: "DraftWrite",

  components: {
    DraftWriteModel,
    DraftGuide,
    DraftApprLine,
    DraftInfo,
    DraftFile,

    FileView,
    PdfViewer,
    pdf,
  },
  data() {
    return {
      model: new DraftWriteModel(),
      isPopGoodsNameSearch: false,
      selectedGoodsName: {},
      isPopProjectCodeSearch: false,
      goodsNameTargetIndex: null,
      projectCodeTarget: { type: "", index: null }, // 기안금액 info, 구매요청품목 goods 구분
      selectedProject: {},
      docStatus: Object.freeze(docStatus),
      routeOut: false,
      draftApprLineinitData: {},
      assetType: assetType,
      draftDeptApprLineAlertText: "샐장을 추가해주세요",
      dataList: [],
      attachFileList: [],
      numPages: 3,
      PDFDataObj: {},
      PDFSysFilePath: "",
    };
  },

  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
  },
  mixins: [CommLayerMixin, commDocs, commWriteDoc],

  created() {
    this.getInitPdfData();
  },

  beforeMount() {},

  async mounted() {
    if (
      !this.login.deptCode ||
      this.login.deptCode == "" ||
      !this.login.deptName ||
      this.login.deptName == ""
    ) {
      await this.saveMyAccountInfoToVuex();
    }

    const isModifyMode = this.$route.meta.isModifyMode;
    const targetPk = this.$route.params.id;
    if (isModifyMode === true && !targetPk) {
      this._showLayer({ type: "alert", content: "잘못된 접근입니다." });
      await this.$router.push(this.$routerPath.PAYMENT_DRAFT_TEMP);
    } else if (isModifyMode === true && targetPk) {
      await this.fetchAndInitStoredDraftData(targetPk);
    }
  },

  beforeRouteLeave(to, from, next) {
    if (!this.routeOut) {
      this.next = next;
      this._showLayer(
        {
          type: "confirm",
          content:
            "작성중인 문서를 취소하시겠습니까?<br>임시저장하지 않을 경우 작성중인 내용이 삭제됩니다.",
        },
        next,
      );
    } else {
      next();
    }
  },
  /**
   * 임시 저장 문서에서 구매 요청서 페이지로 바로 진입시 기존데이터가 남아있는 현상발생.
   * 직전에 임시저장 문서에서 들어왔다면 수동으로 새로고침 해준다.
   * */
  // beforeRouteEnter(to, from, next) {
  //   if (from.path.startsWith('/aprvl/tempDraft')) {
  //     next((vm) => vm.$router.go(0));
  //     return;
  //   }
  //   next();
  // },
  methods: {
    getInitPdfData() {
      let result = {
        timestamp: "20220113135951",
        code: "200",
        text: "성공",
        data: {
          sysFilePath:
            "2f7661722f746d702f32303232303131332f32303232303131333133353935313433385f39444331304334454130434234344234423933393534304546394241454441322e706466",
          orgFile: "default.pdf",
          menuId: "contract",
        },
      };

      const { code, data } = result;
      if (code !== "200") {
        alert("error");
      }
      this.dataList = data;
    },

    async onUpdateSelectedDraftDept(selectedDeptCode) {
      this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(selectedDeptCode);
      this.model.deptCode = selectedDeptCode;
    },
    onUpdateDraftDeptApprLine(draftDeptApprLine) {
      this.model.draftDeptApprLine = draftDeptApprLine;
    },
    onUpdateProcessingApprovalLine(processingApprovalLine) {
      this.model.processingApprovalLine = processingApprovalLine;
    },
    onUpdateReferenceApprovalLine(referenceApprovalLine) {
      this.model.referenceApprovalLine = referenceApprovalLine;
    },
    onSelectedApprovalLineAccount(approvalLineAccounts) {
      this.model.approvalLineAccounts = approvalLineAccounts;
      this.draftDeptApprLineAlert(approvalLineAccounts.name);
      this._showLayer({
        type: "alert",
        content: "발제부서라인에 " + this.draftDeptApprLineAlertText,
      });
    },
    draftDeptApprLineAlert(name) {
      // 임시 결재선 발제부서선택 알럿 - 추후 결재선 자동화 기능 적용 이후 제거 예정
      if (name.indexOf("3억") > -1) {
        // 3억초과
        this.draftDeptApprLineAlertText = "셀장, 파트장, 팀장, 실장을 추가해주세요";
      } else if (name.indexOf("5,000만원 초과") > -1) {
        // 5,000만원 초과
        this.draftDeptApprLineAlertText = "셀장, 파트장, 팀장, 실장을 추가해주세요";
      } else if (name.indexOf("5,000만원 이하") > -1) {
        // 5,000만원 이하
        this.draftDeptApprLineAlertText = "셀장, 파트장, 팀장을 추가해주세요";
      } else if (name.indexOf("100만원 이하") > -1) {
        // 100만원 이하
        this.draftDeptApprLineAlertText = "셀장을 추가해주세요";
      }
    },
    cancelDraft() {
      // const content = '작성중인 문서를 취소하시겠습니까?<br/>임시저장하지 않을 경우 작성중인<br/>내용이 삭제됩니다.';
      // this._showLayer({type: 'confirm', content}, () => {
      //   this.$router.back();
      // }, () => { });
      if (this.$route.meta.isModifyMode) {
        this.$router.push({
          path: this.$paymentRouterPath.PAYMENT_DRAFT_TEMP,
        });
      } else {
        this.$router.push({
          path: this.$paymentRouterPath.PAYMENT_DRAFT_ING,
        });
      }
    },
    async save(docStatus) {
      const errorMsg = this.model.validate(docStatus === this.docStatus.TEMPORARY);
      if (errorMsg) {
        this._showLayer({ type: "alert", content: errorMsg });
        return;
      }
      this.model.docStatus = docStatus;
      this.model.deptName = this.login.deptName;
      this.model.email = this.login.email;
      this.model.mobile = this.login.mobile;
      this.model.myDraftDeptInfo = new ApprovalLineModel(
        this.login.empNo,
        this.login.deptCode,
        aprvlLineType.DRAFT,
        "",
        true,
      );

      // this.model.contents = $('#summernote').summernote('code');

      // 구매요청 결재선선택 및 발제부서 인원에 따른 alert

      if (this.model._draftDeptApprLine.length < 1) {
        return; //임시 막음
      }

      const formData = this.model.makeFormData();
      const isModifyMode = this.$route.meta.isModifyMode;
      const targetPk = this.$route.params.id;

      let res;

      //formData.data.aprvlLineList = formData.aprvlLineList;
      if (isModifyMode && targetPk) {
        formData.targetPk = targetPk;

        res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_LIST, formData);
      } else {
        res = await ApiService.shared.post(this.$apiPath.APRVL_DOC_LIST, formData);
      }

      if (res.code !== "200") {
        this._showLayer({
          type: "alert",
          content: e.text || "처리중 에러가 발생하였습니다. 재시도해주세요.",
        });
        console.error(e);
        return;
      }
      this.routeOut = true;

      await this.$router.push(
        docStatus === this.docStatus.TEMPORARY
          ? this.$paymentRouterPath.PAYMENT_DRAFT_TEMP
          : this.$paymentRouterPath.PAYMENT_DRAFT_ING,
      );
    },
    async fetchAndInitStoredDraftData(targetPk) {
      const data = await this.fetchStoredDraftData(targetPk);
      this.onUpdateSelectedDraftDept(data.drftDeptCode);
      this.draftApprLineinitData = this.makeDraftApprLineInitData(data);
      this.model.initFromFetchData(data);
    },
    /**
     * 결제선 지정 인원 생성
     * @param aprvlLineList
     * @return {{approvalLine: *, draftDeptName: *, aprvlLineAccntNo: default.methods.searchForm.selectedPayAccount.code, draftLine: *, referrerLine: *}}
     */
    makeDraftApprLineInitData({ aprvlLineList, aprvlLineAccntNo, drftDeptName }) {
      const draftLine = aprvlLineList
        .filter((e) => e.aprvlLineType === aprvlLineType.DRAFT || e.prsntDeptYn == true)
        .filter((e) => e.accountId !== this.login.accountId.replace(".ke", ""))
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);

      // 21.3.19 재승인 요청 시 본인이 기안자에 2번 들어가는 현상으로 지정 인원 생성 시 배열의 처음 원소를 삭제 처리합니다.
      draftLine.shift();

      const approvalLine = aprvlLineList
        .filter((e) => e.aprvlLineType === aprvlLineType.APPROVAL && e.prsntDeptYn == false)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);

      const referrerLine = aprvlLineList
        .filter((e) => e.aprvlLineType === aprvlLineType.REFERENCE)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);

      return {
        aprvlLineAccntNo: aprvlLineAccntNo,
        draftDeptName: drftDeptName,
        draftLine,
        approvalLine,
        referrerLine,
      };
    },
  },
};
</script>
